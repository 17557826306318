import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/SEO"
import { Container, Main, BlogCard } from "../components/UI"
import Layout from "../components/Layout"

const AllAuthorPostTemplates = ({ data: { allDatoCmsBlog }, location }) => {
  const canonicalUrl = `https://thetoucan.app${location.pathname}`

  return (
    <Layout>
      <Seo
        title="Blogs"
        description="Blogs by Toucan"
        canonicalUrl={canonicalUrl}
      />
      <Main className="bg-white py-14 md:py-16 lg:py-32">
        <Container className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-8 lg:grid-cols-3 lg:gap-12">
          {React.Children.toArray(
            allDatoCmsBlog.nodes.map(p => (
              <BlogCard
                title={p.title}
                slug={p.slug}
                image={p.heroImage}
                author={p.author}
                category={p.category}
                excerpt={p.excerptFromBody}
              />
            ))
          )}
        </Container>
      </Main>
    </Layout>
  )
}

export const query = graphql`
  query GetAuthorPost($slug: String!) {
    allDatoCmsBlog(filter: { author: { slug: { eq: $slug } } }) {
      nodes {
        title
        slug
        excerptFromBody
        heroImage {
          gatsbyImageData(imgixParams: { maxW: 100, maxH: 100 })
          alt
        }
        author {
          name
          slug
        }
        category {
          name
          slug
        }
      }
    }
  }
`

export default AllAuthorPostTemplates
